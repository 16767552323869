$(document).ready(function() {
  // your code
  var window = $(window);

  $('#nav-icon2').click(function(){
	
    var target = $(this).data('alvo');
    

    if (!$(this).hasClass('open')){
    $(target).animate({
      right: 0,
    },function(){
          $("#nav-icon2").toggleClass('abrir');	
        })
    }
    else{
      $(target).animate({
        right:'-100%',
    },function(){
          $("#nav-icon2").toggleClass('abrir');	
        })
    }
    $(this).toggleClass('open');
  });



})